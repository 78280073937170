<template>
	<div>
		<div class="row">
			<div class="col-12 col-xl-6">
				<div class="card">
					<div class="card-header">
						<div>
							<h4 class="card-header-title">New Amenities</h4>
						</div>
					</div>
					<div class="card-body">
						<div
							class="alert alert-danger alert-dismissible fade show"
							role="alert"
							v-if="errorMessage"
							>
							{{ errorMessage }}
						</div>
						<div>
							<form @submit.prevent="createAmenity">
								<div class="row">
									<div class="col-12 col-md-12">
										<div class="form-group">
											<label class="form-label text-secondary font-weight-bold"> Name </label>
											<input
												v-model.trim="form.name"
												required
												type="text"
												placeholder="Enter name"
												class="form-control"
												/>
										</div>
										<div class="form-group">
											<label class="form-label text-secondary font-weight-bold">Short name </label>
											<input
												v-model.trim="form.short_name"
												required
												type="text"
												placeholder="E.g AC"
												class="form-control"
												/>
										</div>
										<p class="text-secondary">Upload Image</p>
										<div id="preview">
											<img class="imageDimensions" v-if="form.previewUrl " :src="form.previewUrl" />
										</div>
										<div v-if="!form.previewUrl" class="d-flex align-items-center mt-3">
											<img src="@/assets/img/info_circle.svg"/>
											<small class="text-muted text-secondary font-weight-light pl-3">Images to be uploaded must have a dimension of 24x24 px</small>
										</div>

										<label class="custom-file-upload border-0 d-flex justify-content-between align-items-center mt-4 w-50 py-3 rounded cursor-pointer" style="background-color : #F0F0FF; color : #4848ED">
											Upload Image
											<input type="file" accept="image/*" @change="onFileSelected" />
											<img src="@/assets/img/upload_image.svg"/>
										</label>

									</div>
								</div>

								<button
									type="submit"
									:disabled="!form.imageUrl && !form.short_name && !form.name"
									:class="[form.imageUrl && form.name && form.short_name ? 'btn btn-dark' : '']"
									class="btn text-white mt-4 btn-primary"
									>
									{{ processing ? 'Creating amenity...' : `Add amenity` }}
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- CATEGORIES LIST -->
		<div>
			<div class="row mt-4">
				<div class="col-12">
					<div class="card">
						<div class="card-header justify-content-between">
							<h4 class="card-header-title">Amenities List</h4>
						</div>
						<div class="card-body">
							<b-table
								striped
								hover
								selectable
								responsive
								show-empty
								empty-text="No Amenities available"
								:items="amenitiesList"
								:fields="fields"
								:per-page="perPage"
								:current-page="currentPage"
								:busy="loading"
								>
								<template #table-busy>
									<div class="text-center text-secondary my-2">
										<strong>Loading...</strong>
									</div>
								</template>
								<template #cell(image)="data">
									<img class="imageDimensions rounded-circle mr-2" :src="data.item.image" />
									<span class="font-weight-normal">{{data.item.short_name}}.{{data.item.image.split(".")[5]}}</span>
								</template>
								<template #cell(date_added)="data">
									<span class="font-weight-normal">{{ data.item.created_at | date('d MMMM, yyyy') }}</span>
								</template>
								<template #cell(name)="data">
									<span class="font-weight-normal"> {{ data.item.name }}</span>
								</template>
								<template #cell(short_name)="data">
									<span class="font-weight-normal"> {{ data.item.short_name }}</span>
								</template>
								<template #cell(action)="data">
									<img @click="handleAmenityDelete(data.item.id)" src="@/assets/img/delete_bucket.svg" />
								</template>
							</b-table>

							<div class="card-footer" v-if="amenitiesList.length">
								<b-pagination
									v-model="currentPage"
									:total-rows="amenitiesList.length"
									:per-page="perPage"
									></b-pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { extractErrorMessage } from '@/utils/helpers'
export default {
  data () {
    return {
      processing: false,
      errorMessage: '',
      loading: false,
      amenitiesList: [],
      fields: [
        {
          key: 'image',
          label: 'IMAGE'
        },
        {
          key: 'date_added',
          label: 'Date Created'
        },
        {
          key: 'name',
          label: 'NAME'
        },
        {
          key: 'short_name',
          label: 'SHORT NAME'
        },
        {
          key: 'action',
          label: 'ACTION'
        }
      ],
      perPage: 10,
      currentPage: 1,
      form: {
        name: '',
        short_name: '',
        image_url: null,
        previewUrl: null
      },
      confirmDeletion: false
    }
  },
  mounted () {
    this.fetchVehicleAmenities()
  },
  methods: {
    handleAmenityDelete (id) {
      this.$swal({
        title: '<strong><h2>You’re about to delete an amenity</h2></strong',
        html: '<p>You’re about to delete this vehicle amenity, if this is action was not intentional please cancel</p>',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Cancel',
        confirmButtonColor: '#000000',
        cancelButtonTextColor: '#000000',
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          this.triggerDeletion(id)
        } else {
          this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      })
    },

    async triggerDeletion (id) {
      try {
        await this.axios.delete(`/v1/vehicle-amenities/${id}`)
        this.$swal(
          'Deleted',
          'You successfully deleted this file',
          'success')
        this.fetchVehicleAmenities()
      } catch (error) {
        const msg = extractErrorMessage(error, 'An error occurred, please try again.')
        this.$swal().showValidationMessage(msg)
      }
    },

    async fetchVehicleAmenities () {
      this.loading = true
      const amenities = await this.axios.get('/v1/vehicle-amenities?metadata=true')
      this.amenitiesList = amenities.data?.data ?? []
      this.loading = false
    },
    onFileSelected (e) {
      const fileReader = new FileReader()
      this.form.previewUrl = URL.createObjectURL(e.target.files[0])

      fileReader.onload = (e) => {
        this.form.image_url = e.target.result
      }

      fileReader.readAsDataURL(e.target.files[0])
    },

    resetFields () {
      this.form.name = ''
      this.form.short_name = ''
      this.form.image_url = null
      this.form.previewUrl = null
    },
    async createAmenity () {
      this.processing = true
      this.errorLoading = false
      const amenitiesPayload = {
        name: this.form.name,
        short_name: this.form.short_name,
        image: this.form.image_url
      }

      try {
        await this.axios.post('/v1/vehicle-amenities?metadata=false', amenitiesPayload)
        this.$swal({
          icon: 'success',
          title: 'Success',
          text: 'You’ve successfully created an amenity',
          showCloseButton: true
        })
        this.fetchVehicleAmenities()
        this.resetFields()
      } catch (error) {
        this.errorLoading = true
        this.processing = false
        const msg = extractErrorMessage(error, 'An error occurred, please try again.')
        this.$swal().showValidationMessage(msg)
      } finally {
        this.processing = false
      }
    }
  }
}
</script>

<style scoped>
    input[type="file"] {
      display: none;
  }
  .custom-file-upload {
      border: 1px solid #ccc;
      display: inline-block;
      padding: 6px 12px;
      cursor: pointer;
  }
  .imageDimensions {
     max-width: 30px;
     height: 30px;
  }

.swal-text {
  background-color: #FEFAE3;
  padding: 17px;
  border: 1px solid #F0E1A1;
  display: block;
  margin: 22px;
  text-align: center;
  color: red;
}
</style>
